@import './../../global.sass'

.Footer
  width: 100%
  background: $primary
  color: white
  @media screen and (min-width: $media-boundary-large)
    padding: 17px 80px
  @media screen and (max-width: $media-boundary-large)
    padding: 17px 40px
  justify-items: center

  p
    text-align: center
  &__img
    width: 100%
    max-width: 250px
  &__baner
    max-width: 120px
    margin-top: -10px
  &__text-with-icon
    display: flex
    gap: 20px
    align-items: center
    justify-content: center
    img
      width: auto

.mobileContact
  position: fixed
  top: 50%
  right: 0
  transform: translateY(-50%)
  padding: 0
  height: 45px
  width: 45px
  display: none
  justify-content: center
  align-items: center
  background: $primary
  border: 2px solid #e3e3e3
  border-right: none
  border-radius: 4px 0 0 4px
  z-index: 999
  @media only screen and (hover: none) and (pointer: coarse)
    display: flex
  svg
    fill: #e3e3e3