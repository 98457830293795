@import './../../global.sass'
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Smooch&display=swap')

.overflow-container
  overflow-x: auto

table
  border: 1px solid $border
  border-collapse: collapse
  td, th
    border: 1px solid $border
    padding: 10px 5px
    white-space: nowrap
    font-size: 18px
  td
    text-align: right
  tr td:first-child
    font-weight: bold
    text-align: left

.charts
  margin-top: 20px
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: 20px
  justify-content: center
  overflow-x: auto
  .kolowy
    max-width: 650px
    @media screen and (min-width: $media-boundary-xlarge)
      width: 55%
  img
    max-width: 1000px
    background: #F2F4F5
    border: 1px solid $border
    border-radius: 4px
    flex: 1 1 49%
  @media screen and (max-width: $media-boundary-small)
    justify-content: left
