@import './../../global.sass'

.History
  padding: 0 50px 0 50px
  position: relative
  &__title
    height: 150px
    display: flex
    justify-content: center
    align-items: center
    background-color: #172f73
    color: #fafafa
    -webkit-clip-path: polygon(0 0,100% 0,100% 60%,75% 100%,25% 100%,0 60%)
    clip-path: polygon(0 0,100% 0,100% 60%,75% 100%,25% 100%,0 60%)
    margin: 0 auto -4px 1rem
    @media screen and (min-width: $media-boundary-small)
      width: 400px
    @media screen and (max-width: $media-boundary-small)
      width: 100%
      margin: 0
  &__image-container
    width: 100%
    display: flex
    @media screen and (min-width: $media-boundary-small)
      padding: 50px 0 50px 10px
    @media screen and (max-width: $media-boundary-small)
      justify-content: center
      padding: 50px
  &__arrow
    position: absolute
    left: 164px
    bottom: -50px
    background: black
    @media screen and (min-width: $media-boundary-small)
      &::before
        content: ""
        position: absolute
        bottom: -3px
        width: 4px
        height: 25px
        background-color: #101010
        right: -8px
        rotate: 45deg
      &::after
        content: ""
        position: absolute
        bottom: -3px
        width: 4px
        height: 25px
        background-color: #101010
        right: 8px
        rotate: -45deg
  h2
    font-weight: bold
    position: relative
    display: flex
    flex-direction: row-reverse
    align-items: center
    text-align: right
    &::after
      content: ""
      flex-grow: 1
      height: 3px
      background-color: #3aa8ff
      margin-right: 2rem
      margin-left: 160px
      @media screen and (max-width: $media-boundary-small)
        margin-left: 0
    @media screen and (min-width: $media-boundary-small)
      &::before
        content: ""
        position: absolute     
        top: 50%
        left: 152px
        transform: translateY(-50%)
        width: 20px
        height: 20px
        background-color: #3aa8ff
        border-radius: 50%
  &__content
    position: relative
    @media screen and (min-width: $media-boundary-small)
      width: 100%
      &:last-child
        &::before
          content: ""
          position: absolute
          top: -1rem
          left: 160px
          width: 4px
          height: calc(100% + 4rem)
          background: linear-gradient(180deg,#172f73,#002345 98.64%)
    @media screen and (min-width: $media-boundary-small)
      &::before
        content: ""
        position: absolute
        top: -1rem
        left: 160px
        width: 4px
        height: calc(100% + 4rem)
        background-color: #172f73
    &__text
      @media screen and (min-width: $media-boundary-small)
        padding-left: 200px
      @media screen and (max-width: $media-boundary-small)
        padding-left: 0
    &__date
      @media screen and (min-width: $media-boundary-small)
        position: absolute
        top: -22px
        left: 0
        &::before
          content: ""
          position: absolute
          bottom: -2px
          left: 0
          width: 200px
          height: 3px
          background-color: #3aa8ff
      @media screen and (max-width: $media-boundary-small)
        text-align: right
