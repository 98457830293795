@import './../../global.sass'

.BrandCard
  display: flex
  flex-wrap: wrap
  align-content: space-between
  background: white
  position: relative
  text-align: left
  &__absolute
    position: absolute
    z-index: 2
    background: white
    transform: translateX(-21px)
    padding: 0px 20px 20px 20px
    border: 1px solid $border
    border-top: none
    width: calc(100% + 2px)
    font-size: 20px
    box-shadow: 0px 45px 42px 0px rgba(66, 68, 90, 0.5)
    border-bottom-left-radius: 4px
    border-bottom-right-radius: 4px
    overflow-y: hidden
    visibility: hidden
    height: 0
  &--expanded
    .BrandCard__absolute
      height: auto
      visibility: visible
  &__link
    font-weight: bold
    color: $primary
    &:hover
      text-decoration: underline
