@import './../../global.sass'
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Smooch&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap') 

.heading
  width: 100%
  margin-bottom: 60px
  video
    width: 100%

.Home
  .header
    @media screen and (min-width: $media-boundary-large)
      position: fixed
      top: 0
      left: 0
    @media screen and (max-width: $media-boundary-large)
      position: sticky
      top: 0
  .transparent
    @media screen and (min-width: $media-boundary-large)
      background: transparent
    @media screen and (max-width: $media-boundary-large)
      background: $primary
  .text
    &--primary
      color: $primary
    &--handwritten
      font-size: 28px
      font-family: 'Alex Brush', serif
      white-space: nowrap
    &--light-gray
      color: $text-lightgray
  .section
    width: 100%
    display: flex
    gap: 40px
    flex-wrap: wrap
    @media screen and (min-width: $media-boundary-large)
      scroll-margin-top: 120px
      padding: 40px 80px
    @media screen and (max-width: $media-boundary-large)
      padding: 30px 20px
      scroll-margin-top: 80px
    &--gray
      @extend .section
      background-color: $gray
    &__center
      width: 100%
      display: flex
      justify-content: center
    &__form 
      width: 100%
      margin: 0 auto
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      align-items: center
      gap: .5rem
      div
        width: 100%
        @media(min-width: 470px)    
          &:nth-child(2), &:nth-child(3)
            width: calc(50% - .5rem) 
        @media(min-width: 630px)
          &:nth-child(1), &:nth-child(2), &:nth-child(3)
            width: calc(33% - .5rem)
      label
        margin-bottom: .25rem
        width: 100%
        display: block 
        &:last-of-type
          margin-bottom: 0
      input, textarea
        margin-bottom: 1rem
        padding: .25rem
        width: 100%
        font-family: 'Inter', sans-serif
        font-size: 1.3rem
        border: 1px solid #e3e3e3
        border-radius: 4px
        outline: none
        resize: none
        &:focus
          border-color: #c4c4c4
      textarea
        height: 250px
      button
        margin: 0 auto
        padding: 15px 30px
        display: block
        text-transform: uppercase
    &__block
      width: 100%
      box-shadow: 0px 0px 10px 0px rgba(16, 16, 16, 0.10)
      border-radius: 4px
      padding: 20px
      font-size: 25px
      &--border
        border: 1px solid $border
        box-shadow: none
      &--white
        background: white
      &--transparent
        background: transparent
      &__boss
        @extend .section__block
        @media screen and (min-width: $media-boundary-large)
          display: flex
          align-items: flex-end
          width: calc(100% - 180px)
          margin-bottom: 35px
          .boss
            width: 150px
            img
              height: 200px
              transform: translate(140px, -2px) scale(2.2)
            p
              transform: translate(45px, 90px)
              white-space: nowrap
        @media screen and (max-width: $media-boundary-large)
          text-align: center
          .boss
            display: flex
            flex-wrap: wrap-reverse
            justify-content: center
            img
              display: block
              transform: translate(0, 20px)
            p
              width: 100%
              text-align: center
              white-space: nowrap
  h2
    text-align: center
    font-size: 35px
    font-weight: 600
    background: $primary
    padding: 50px 15px 15px 15px
    color: white
    border-radius: 4px
    @media screen and (max-width: $media-boundary-large)
      text-align: center
  
  .card
    color: $text-gray
    &--small
      @extend .card
      img
        width: 60px
        height: 60px
        margin-bottom: 5px
      h3, p
        margin: 0
      text-align: center

  .nav
    display: grid
    position: relative
    border-radius: 4px
    background: white
    width: 100%
    @media screen and (min-width: $media-boundary-large)
      grid-template-columns: repeat(7, 1fr)
    @media screen and (max-width: $media-boundary-large)
      grid-template-columns: repeat(1, 1fr)
      gap: 5px
    &__indicator
      position: absolute
      background: $primary
      height: 55px
      @media screen and (min-width: $media-boundary-large)
        width: calc(100% / 7)
        transition: 300ms
      @media screen and (max-width: $media-boundary-large)
        width: 100%
      border-radius: 4px
      z-index: 1
      
      &--hover
        @extend .nav__indicator
        background: transparent
    &__option
      display: flex
      align-items: center
      justify-content: center
      z-index: 2
      transition-duration: 50ms
      height: 55px
      padding: 15px
      width: 100%
      cursor: pointer
      &:hover
        color: white
      &--active
        @extend .nav__option
        color: white
    &__menu-button--mobile
      display: none
      @media screen and (max-width: $media-boundary-large)
        display: block
        padding: 15px
        background: $primary
        color: white
        border-radius: 4px
        width: 100%
        text-align: center
  .baner
    width: 100%
    background: white
    display: grid
    grid-template-columns: repeat(3, 1fr)
    padding: 55px
    gap: 65px
    align-items: center
    justify-items: center
    img
      width: 100%
      max-width: 150px
  .document-thumbnail
    width: 100%
    transition-duration: 100ms
    &:hover
      transform: scale(1.1)
      box-shadow: 0px 10px 10px 10px rgba(16, 16, 16, 0.10)
  .awards
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center
    gap: 20px
    &>*
      max-width: 250px
      min-width: 150px
  .logo
    width: 100%
    display: flex
    justify-content: center
    img
      width: 80%
      max-width: 400px
  .our-brands
    display: flex
    flex-wrap: wrap
    gap: 20px
    width: 100%
    justify-content: center
    &>*
      max-width: 631px
      min-width: 300px
      flex: 1 1 32%

  .nav--hidden
    @media screen and (max-width: $media-boundary-large)
      display: none
h3
  border-bottom: 3px solid $primary
  display: inline-block
  margin: 1em 0

#option--bold
  font-weight: bold
  text-decoration: underline